const FONT_SIZES: Record<string, number> = {
  "large": 40,
  "regular": 18,
};

export type FontSize = keyof typeof FONT_SIZES;

const FONT_WEIGHTS: Record<string, number> = {
  "heavy": 700,
  "regular": 500,
};

export type FontWeight = keyof typeof FONT_WEIGHTS;

export const getFontSize = (sizeName: FontSize): number => {
  return FONT_SIZES[sizeName] || 16;
};

export const getFontWeight = (fontWeightName: FontWeight): number => {
  return FONT_WEIGHTS[fontWeightName] || FONT_WEIGHTS["regular"];
};
