import React from 'react';

import classnames from "classnames";

import './DarkModeToggle.css';

interface Props {
  onUpdate: () => void;
  isDarkMode: boolean;
}

const DarkModeToggle: React.FC<Props> = ({
  onUpdate,
  isDarkMode,
}) => {
  const toggleDarkMode = () => {
    localStorage.setItem("isDarkMode", (isDarkMode) ? "false" : "true");
    onUpdate();
  };

  return (
    <div className={classnames("DarkModeToggle", {
      "DarkModeToggle-dark": isDarkMode,
    })} onClick={toggleDarkMode}/>
  );
};

export default DarkModeToggle;
