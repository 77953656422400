interface StarClusterConfig {
  canvasHeight: number;
  canvasWidth: number;
  context: CanvasRenderingContext2D;
  velocity: number;
  velocityVariance: number;
  clusterSize: number;
  radius: number;
  spread: number;
  strokeStyle: string;
}

interface StarConfig {
  coreX: number;
  coreY: number;
  canvasHeight: number;
  canvasWidth: number;
  velocity: number;
  velocityVariance: number;
  spread: number;
}

interface StarCore {
  x: number;
  y: number;
  vx: number;
  vy: number;
}

class Star {
  x: number;
  y: number;
  vx: number;
  vy: number;
  radius: number;

  constructor({
    coreX,
    coreY,
    canvasWidth,
    canvasHeight,
    velocity,
    velocityVariance,
    spread,
  }: StarConfig) {
    let r = spread;
    this.x = Math.abs((coreX - r/2) + Math.random() * r) % canvasWidth;
    this.y = Math.abs((coreY - r/2) + Math.random() * r) % canvasHeight;
    this.vx = velocity - (Math.random() * velocityVariance);
    this.vy = velocity - (Math.random() * velocityVariance);
    this.radius = Math.random() *  0.8;
  }

  render(context: CanvasRenderingContext2D) {
    context.beginPath();
    context.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    context.fill();
  }

  dist(s: StarCore): number {
    return Math.sqrt(Math.pow(this.x - s.x, 2) + Math.pow(this.y - s.y, 2));
  }

  tick(canvas: HTMLCanvasElement) {
    if (this.y < 0 || this.y > canvas.height) {
      this.vy *= -1;
    }

    if (this.x < 0 || this.x > canvas.width) {
      this.vx *= -1;
    }

    this.x += this.vx;
    this.y += this.vy;
  }
}

class Cluster {
  stars: Star[];
  coreX: number;
  coreY: number;
  coreVx: number;
  coreVy: number;
  velocity: number;
  velocityVariance: number;
  length: number;
  strokeStyle: string;
  spread: number;
  radius: number;

  constructor({
    canvasWidth,
    canvasHeight,
    velocity,
    velocityVariance,
    clusterSize,
    spread,
    radius,
    strokeStyle,
  }: StarClusterConfig) {
    this.stars = [];
    this.coreX = Math.random() * canvasWidth;
    this.coreY = Math.random() * canvasHeight;
    this.coreVx = velocity - (Math.random() * velocityVariance);
    this.coreVy = velocity - (Math.random() * velocityVariance);
    this.length = Math.ceil(clusterSize * Math.random()) + 1;
    this.velocity = velocity;
    this.velocityVariance = velocityVariance;
    this.spread = spread;
    this.radius = radius;
    this.strokeStyle = strokeStyle;
  }

  tick(canvas: HTMLCanvasElement) {
    this.updateCore(canvas);

    this.stars.forEach(s => s.tick(canvas));
  }

  updateCore(canvas: HTMLCanvasElement) {
    if (this.coreY < 0 || this.coreY > canvas.height) {
      this.coreVy *= -1;
    }

    if (this.coreX < 0 || this.coreX > canvas.width) {
      this.coreVx *= -1;
    }

    this.coreX += this.coreVx;
    this.coreY += this.coreVy;
  }

  initializeStars(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
    for (let i = 0; i < this.length; i++) {
      this.stars.push(new Star({
        coreX: this.coreX,
        coreY: this.coreY,
        canvasHeight: canvas.height,
        canvasWidth: canvas.width,
        velocity: this.velocity,
        velocityVariance: this.velocityVariance,
        spread: this.spread,
      }));
      this.stars[i].render(context);
    }
  }

  renderStars(context: CanvasRenderingContext2D) {
    this.stars.forEach(s => s.render(context));
  }

  renderLines(context: CanvasRenderingContext2D) {
    context.strokeStyle = this.strokeStyle;
    for (let i = 0; i < this.stars.length; i++) {
      for (let j = 0; j < this.stars.length; j++) {
        if (i !== j) {
          const iStar = this.stars[i];
          const jStar = this.stars[j];

          const core = {
            x: this.coreX,
            y: this.coreY,
            vx: this.coreVx,
            vy: this.coreVy,
          };

          if (iStar.dist(jStar) < this.radius && iStar.dist(core) < this.radius) {
            context.beginPath();
            context.moveTo(iStar.x, iStar.y);
            context.lineTo(jStar.x, jStar.y);
            context.stroke();
            context.closePath();
          }

        }
      }
    }
  }
}

interface ConstellationControls {
  start: () => void;
  stop: () => void;
}

const constellations = (
  canvas: HTMLCanvasElement,
  isDarkMode: boolean,
): ConstellationControls => {
  const context = canvas.getContext('2d');

  if (! context) {
    return {
      start: () => {},
      stop: () => {},
    };
  }

  context.fillStyle = isDarkMode ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 1)';
  context.lineWidth = 0.1;
  context.scale(1, 1);

  const numClusters = 10;
  const velocity = 0.2;
  const velocityVariance = 0.4;
  const clusterSize = 300;
  const radius = 150;
  const spread = 800;
  const clusterStrokeStyle = isDarkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.1)';

  const clusters: Cluster[] = [];
  let shouldStop: boolean = false;

  const stop = () => {
    shouldStop = true;
  };

  const tick = () => {
    context.clearRect(0, 0, canvas.width, canvas.height);
    clusters.forEach(c => {
      c.renderStars(context);
      c.renderLines(context);
      c.tick(canvas);
    });

    if (! shouldStop) {
      requestAnimationFrame(tick);
    }
  };

  for (let i = 0; i < numClusters; i++) {
    clusters.push(new Cluster({
      canvasHeight: canvas.height,
      canvasWidth: canvas.width,
      context,
      velocity,
      velocityVariance,
      clusterSize,
      radius,
      spread,
      strokeStyle: clusterStrokeStyle,
    }));

    clusters[i].initializeStars(canvas, context);
  }

  return {
    start: tick,
    stop,
  };
};

export default constellations;
