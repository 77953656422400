import React, { useEffect, useRef } from 'react';

import classnames from "classnames";

import constellations from "components/ui/Canvas/Constellations/stars";

interface Props {
  isDarkMode?: boolean;
}

const ConstellationsCanvas: React.FC<Props> = ({isDarkMode}) => {
  let canvasRef = useRef<HTMLCanvasElement>(null);
  let cleanupRef = useRef<any>(null);

  useEffect(() => {
    const handleResize = () => {
      if (!canvasRef.current) {
        return;
      }
      const canvas: HTMLCanvasElement = canvasRef.current;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      if (cleanupRef.current) {
        cleanupRef.current();
        startAnimation();
      }
    };
    if (! canvasRef.current) {
      return;
    }

    const startAnimation = () => {
      if (!canvasRef.current) {
        return;
      }
      const canvas: HTMLCanvasElement = canvasRef.current;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      const { stop, start } = constellations(canvas, !!isDarkMode);

      cleanupRef.current = stop;

      start();
    };

    window.addEventListener("resize", handleResize);

    startAnimation();

    return () => {
      cleanupRef.current && cleanupRef.current();
    };
   }, [isDarkMode]);

  return <canvas ref={canvasRef} className={
    classnames("canvas", {
      "container-dark": isDarkMode,
    })
  } />;
};

export default ConstellationsCanvas;
