import React, { useEffect, useState } from 'react';
import './App.css';

import classnames from "classnames";

import Text from "components/ui/Text";
import Link from "components/ui/Link";
import Canvas, { CanvasType } from "components/ui/Canvas";
import DarkModeToggle from "components/ui/DarkModeToggle";

import { intersperse } from "utils/arrays";
import { useForceUpdate } from "utils/react";

interface BadgeIconProps {
  onClick: () => void;
}

const BadgeIcon: React.FC<BadgeIconProps> = ({
  onClick,
}) => {
  return (
    <div onClick={onClick} style={{cursor: "pointer"}} className={
      classnames("animate__animated", "animate__bounceIn")
    }>
      <Text fontSize="large">
        <span role="img" aria-label="Man Technologist" className="fade-hover">👨🏻‍💻 </span>
      </Text>
    </div>
  );
};

function App() {
  const companies = [
    {
      name: "Google",
      link: "https://google.com",
    },
    {
      name: "Impira",
      link: "https://impira.com",
    },
    {
      name: "Affirm",
      link: "https://affirm.com",
    },
      /*{
      name: "LinkedIn",
      link: "https://linkedin.com",
    },*/
    {
      name: "UPenn",
      link: "https://upenn.edu",
    },
  ];

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (localStorage.getItem("isDarkMode") === null) {
      localStorage.setItem("isDarkMode", "true");
      forceUpdate();
    }
  }, [forceUpdate]);

  const canvasTypes = [CanvasType.Bridge, CanvasType.Constellations];
  //const [canvasType, setCanvasType] = useState<number>(Math.floor(Math.random() * canvasTypes.length));
  const [canvasType, setCanvasType] = useState<number>(0);

  const companyLine = intersperse( 
    companies.map(({name, link}) => (
        <Link key={`company-${name}`} url={link}>{name}</Link> 
      )), <Text>,&nbsp;</Text>
    );

  const isDarkMode = (localStorage.getItem("isDarkMode") === "true");

  return (
    <>
    <Canvas type={canvasTypes[canvasType]} isDarkMode={isDarkMode}/>
    <div className={classnames("container")}>
      <div className={classnames("row", {"row-dark": isDarkMode})}>
        <div className={classnames("flex-item", "row-container", "margin-bottom-10")}>
          <BadgeIcon onClick={() => {
            setCanvasType((canvasType + 1) % canvasTypes.length);
          }} />
          <Text fontWeight="heavy" fontSize="large">&nbsp;JT Cho</Text>
        </div>
        <div className={classnames("flex-item", "margin-bottom-10")}>
          <Text color="light-gray">
          currently building something new in payments 💸, ask me about it :)
          </Text>
        </div>
        <div className={classnames("flex-item", "row-container")}>
            <Text color="light-gray" fontSize="regular">formerly&nbsp;</Text>{companyLine}
        </div>
        <div className="flex-item">
          <span role="img" aria-label="Black Nib">✍</span> &nbsp;<Link url="https://medium.com/@jtcho">writings</Link>
        </div>
        <div className="flex-item">
          <span role="img" aria-label="Mail">✉️ &nbsp;</span>
          <Link url="mailto:jonathan.t.cho@gmail.com">email</Link>&nbsp;
          <Link url="https://github.com/jtcho">github</Link>&nbsp;
          <Link url="https://www.linkedin.com/in/jtcho/">linkedin</Link>&nbsp;
          <Link url="https://twitter.com/jt_cho">twitter</Link>
        </div>
      </div>
    </div>
    <DarkModeToggle onUpdate={forceUpdate} isDarkMode={isDarkMode}/>
    </>
  );
}

export default App;
