import React from "react";

import { ColorName, getColor } from "components/ui/colors";
import { FontSize, FontWeight, getFontSize, getFontWeight } from "components/ui/fonts";

interface Props {
  fontWeight?: FontWeight;
  fontSize?: FontSize;
  fontStyle?: string;
  isSerif?: boolean;
  color?: ColorName;
}

const Text: React.FC<Props> = ({
  fontWeight="regular",
  fontSize="regular",
  fontStyle="normal",
  isSerif=false,
  color="dark-gray",
  children,
}) => {
  return <p style={{
    fontWeight: getFontWeight(fontWeight),
    fontFamily: isSerif ? "serif" : "sans-serif",
    fontSize: getFontSize(fontSize),
    fontStyle,
    color: getColor(color),
  }}>{children}</p>;
};

export default Text;
