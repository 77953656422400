import React from "react";

import Text from "components/ui/Text";
import { ColorName, getColor } from "components/ui/colors";

interface Props {
  url: string;
  color?: ColorName;
}

const Link: React.FC<Props> = ({
  url,
  color="dark-gray",
  children,
}) => {
  return <a href={url} style={{
    color: getColor(color),
  }} className="fade-hover">
    <Text>{children}</Text>
  </a>;
};

export default Link;
