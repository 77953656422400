import React from 'react';

import classnames from "classnames";

import ConstellationsCanvas from "./Constellations";

import './Canvas.css';

export enum CanvasType {
  Bridge = "bridge",
  Constellations = "constellations",
}

interface Props {
  type: CanvasType;
  isDarkMode?: boolean;
}

const Canvas: React.FC<Props> = ({
  type,
  isDarkMode,
}) => {
  switch(type) {
    case CanvasType.Bridge:
      return (
        <canvas className={
          classnames("canvas", {
            "bridge": !isDarkMode,
            "bridge-dark": isDarkMode,
          })
        }/>
      );
    case CanvasType.Constellations:
      return <ConstellationsCanvas isDarkMode={isDarkMode}/>;
    default:
      return <></>;
  }
};

export default Canvas;
