const COLOR_MAP: Record<string, string> = {
  "dark-gray": "#202020",
  "light-gray": "#909090",
};

const DARK_COLOR_MAP: Record<string, string> = {
  "dark-gray": "#f0f0f0",
  "light-gray": "#eeeeee",
};

export type ColorName = keyof typeof COLOR_MAP;

export const getColor = (colorName: ColorName): string => {
  const isDarkMode = localStorage.getItem("isDarkMode") === "true";
  const colorMap = isDarkMode ? DARK_COLOR_MAP : COLOR_MAP;
  return colorMap[colorName] || colorMap["dark-gray"];
};
